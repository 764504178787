
import {
  computed,
  defineAsyncComponent,
  defineComponent,
  PropType,
  SetupContext,
  WritableComputedRef,
  ref,
} from 'vue';
import { useStore } from 'vuex';
import 'vue2-datepicker/locale/es';
import { mixed, object, string } from 'yup';
import { Form, Field, ErrorMessage } from 'vee-validate';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter.js';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore.js';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(customParseFormat);

/** Interfaces */
import {
  ConfirmFormI,
  ContractSimulatorI,
  NegotiationWithinSimulatorI,
  SimulateNegotiationI,
} from '@/interfaces/negotiation.interface';
import { useUtilities } from '@/composition/useUtilities';

/** Utils */
import { redirectWhatsappAdviser } from '@/utils/general-utils';
import { DataNegotiationTypes } from '@/utils/mutation-types';

export default defineComponent({
  name: 'ModalConfirmNegotiation',
  emits: ['confirm', 'close', 'more-time'],
  props: {
    isVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentNegotiation: {
      type: Object as PropType<SimulateNegotiationI>,
      required: true,
      default: Object.create({}),
    },
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    NewModal: defineAsyncComponent(
      () => import('@/components/shared/NewModal.vue')
    ),
    DatePicker: defineAsyncComponent(() => import('vue2-datepicker')),
  },
  setup(props, { emit }: SetupContext) {
    const store = useStore();
    const { setHomologateProductsTitle } = useUtilities();

    const confirm = (_form: ConfirmFormI) => {
      emit('confirm');
    };

    const close = () => {
      emit('close');
    };

    const moreTime = () => {
      emit('more-time');
      redirectWhatsappAdviser(
        process.env.VUE_APP_WHATSAPP_GESTOR,
        'Quiero cerrar un acuerdo pero necesito un poco más de tiempo'
      );
    };

    const enableSaveOperationDevelopment = computed<boolean>(() => {
      return process.env.NODE_ENV !== 'production';
    });

    const schemaForm = object().shape({
      deadlineDate: mixed()
        .default(null)
        .required('La fecha límite es requerida.'),

      email: string()
        .trim()
        .required('Ingrese un correo')
        .email('Ingrese un correo válido'),
    });

    const updateContractsWithNewPaymentDate = (
      contracts: ContractSimulatorI[],
      paymentDate: string
    ): ContractSimulatorI[] => {
      return contracts.map((contract) => {
        contract.payment_plan.fees = contract.payment_plan.fees.map((fee) => {
          if (fee.fee_type_id === 1) {
            fee.payment_date = paymentDate;
          }

          return fee;
        });

        return contract;
      });
    };

    const updateSimulationWithNewInitialPaymentDate = (
      paymentDate: string
    ): SimulateNegotiationI[] => {
      return [...store.state.dataNegotiation].map(
        (simulation: SimulateNegotiationI) => {
          if (simulation.product_id === props.currentNegotiation?.product_id) {
            const negotiation =
              simulation.negotiation as NegotiationWithinSimulatorI;
            const contracts = updateContractsWithNewPaymentDate(
              negotiation.contracts as ContractSimulatorI[],
              paymentDate
            );

            negotiation.payment_start_date = paymentDate;
            negotiation.contracts = contracts;
            simulation.negotiation = negotiation;
          }

          return simulation;
        }
      );
    };

    const deadlineDate: WritableComputedRef<string> = computed({
      get(): string {
        const simulation = store.state.dataNegotiation.find(
          (item: SimulateNegotiationI) => {
            return item?.product_id === props.currentNegotiation?.product_id;
          }
        );

        return simulation?.negotiation?.payment_start_date
          ? dayjs(simulation?.negotiation?.payment_start_date, [
              'DD/MM/YYYY',
              'YYYY-MM-DD',
            ]).format('DD/MM/YYYY')
          : '';
      },
      set(value: string) {
        const customPaymentStartDate = value
          ? value
          : String(props.currentNegotiation?.negotiation?.payment_start_date);

        const updatedSimulations = updateSimulationWithNewInitialPaymentDate(
          customPaymentStartDate
        );

        store.commit(DataNegotiationTypes.SET_DATA_NEGOTIATION, [
          ...updatedSimulations,
        ]);
      },
    });

    const email: WritableComputedRef<string> = computed({
      get(): string {
        const simulation = store.state.dataNegotiation.find(
          (item: SimulateNegotiationI) => {
            return item?.product_id === props.currentNegotiation?.product_id;
          }
        );

        return simulation?.negotiation?.email ?? '';
      },
      set(value: string) {
        const customEmail = value
          ? value
          : String(props.currentNegotiation?.negotiation?.email);

        const simulation: SimulateNegotiationI[] = [
          ...store.state.dataNegotiation,
        ].map((simulation: SimulateNegotiationI) => {
          if (simulation.product_id === props.currentNegotiation?.product_id) {
            const negotiation =
              simulation.negotiation as NegotiationWithinSimulatorI;

            negotiation.email = customEmail;
            simulation.negotiation = negotiation;
          }

          return simulation;
        });

        store.commit(DataNegotiationTypes.SET_DATA_NEGOTIATION, [
          ...simulation,
        ]);
      },
    });

    const notBeforeToday = (date: Date): boolean => {
      const calendarDate = dayjs(date.setHours(0, 0, 0, 0));
      const today = dayjs().hour(0).minute(0).second(0).millisecond(0);

      const lastDayMonth = dayjs()
        .endOf('month')
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      return !(
        calendarDate.isSameOrAfter(today) &&
        calendarDate.isSameOrBefore(lastDayMonth)
      );
    };

    return {
      close,
      email,
      confirm,
      moreTime,
      schemaForm,
      deadlineDate,
      notBeforeToday,
      setHomologateProductsTitle,
      enableSaveOperationDevelopment,
    };
  },
});
